import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // This defaults to localStorage for web
import { combineReducers } from 'redux';

interface UserDataState {
  username: string;
  role: string;
  theme: string;
}

interface CompetitorDataState {
  configname: string;
}

interface TeaRetinaDataState {
  standards: any;
}

const initialUserData: UserDataState = {
  username: '',
  role: '',
  theme: 'PureLightTheme'
};

const initialCompetitorData: CompetitorDataState = {
  configname: ''
};

const initialTeaRetinaData: TeaRetinaDataState = {
  standards: []
};

const userData = (state = initialUserData, action: any): UserDataState => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};


const competitorData = (state = initialCompetitorData, action: any): CompetitorDataState => {
  switch (action.type) {
    case 'SET_COMPETITOR_DATA':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const teaRetinaData = (state = initialTeaRetinaData, action: any): TeaRetinaDataState => {
  switch (action.type) {
    case 'SET_TEARETINA_DATA':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Combine reducers
const rootReducer = combineReducers({
  userData,
  competitorData,
  teaRetinaData
});

export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userData', 'competitorData'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

function storedata() {
    return { store, persistor };
}

export default storedata;