import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { getPrivilegeList } from 'src/Utils';
import { User } from 'src/models/user_detail';
import { getUserGroups, getUsers } from 'src/Utils';
import { useLocation } from 'react-router-dom';

interface PrivilegeProps {
  role_name: string;
  functionality: string;
  privilege_name: string;
}

interface UserGroupProps {
  group_name: string;
  user_list: string[];
}

interface UserManagementContextProps {
  privileges: PrivilegeProps[];
  setPrivileges: React.Dispatch<React.SetStateAction<PrivilegeProps[]>>;
  usergroups: UserGroupProps[];
  setUserGroups: React.Dispatch<React.SetStateAction<UserGroupProps[]>>;
  roles;
  setRoles;
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
}

const UserManagementContext = createContext<UserManagementContextProps | undefined>(undefined);

const UserManagementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [privileges, setPrivileges] = useState<PrivilegeProps[]>([]);
  const [usergroups, setUserGroups] = useState<UserGroupProps[]>([]);
  const location = useLocation();
  const isSignInRoute = location.pathname === "/";

  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState<User[]>([]);

  useEffect( () => {
    if (!isSignInRoute && users.length == 0) {
      const user_list = getUsers();
  
      user_list.then( (usersList) => {
        const convertedList = usersList['data'].listUsers.map ((user) => {
          let retUser = 
          {
            userName: user.Attributes.find( (attr) => attr.Name === 'name')?.Value ?? '',
            status: user.Enabled ? "Active" : "Inactive",
            familyName: user.Attributes.find( (attr) => attr.Name === 'family_name')?.Value ?? '',
            userRole: user.Attributes.find( (attr) => attr.Name === 'custom:role')?.Value ?? '',
            group: user.Attributes.find(attr => attr.Name === 'custom:group')?.Value ?? '',
            creationDate: user.UserCreateDate,
            email: user.Attributes.find( (attr) => attr.Name === 'email')?.Value ?? ''
          }
          return retUser;
        });
        setUsers(convertedList);
      });
    }
  }, [isSignInRoute, users]);

  useEffect( () => {
    if (!isSignInRoute) {
      const groups_promise = getUserGroups({group_name: ""});
      groups_promise.then( (groups) => {
        const group_list = JSON.parse(groups['data'].getGroup.result)
        const result = group_list.reduce((acc, [group, user]) => {
          const groupIndex = acc.findIndex(item => item.group_name === group);  
          if (groupIndex >= 0) {
            acc[groupIndex].user_list.push(user);
          } else {
            acc.push({
              group_name: group,
              user_list: [user]
            });
          }  
          return acc;
        }, []);
        setUserGroups(result);
      }).catch( e => console.log("Error occurred: ", e));
    }
  }, [isSignInRoute]);

  useEffect( () => {
    if (!isSignInRoute && privileges.length === 0) {
        Auth.currentAuthenticatedUser().then((user) => {
            const privileges = getPrivilegeList({roles: [user.attributes['custom:role']]});
            privileges.then( (privilegeList) => {
                setPrivileges(JSON.parse(privilegeList['data'].getPrivileges));
            });
        })
    }
  }, [isSignInRoute, privileges]);

  return (
    <UserManagementContext.Provider value={{ privileges, setPrivileges, usergroups, setUserGroups, users, setUsers, roles, setRoles }}>
      {children}
    </UserManagementContext.Provider>
  );
};

export { UserManagementContext, UserManagementProvider };
