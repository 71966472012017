/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles {
    listUserRoles {
      role_name
    }
  }
`;
export const getGrades = /* GraphQL */ `
  query GetGrades {
    getGrades {
      grade
    }
  }
`;
export const getBuyers = /* GraphQL */ `
  query GetBuyers {
    getBuyers {
      buyer
      buyer_name
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory {
    getCategory {
      category
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers {
    getCustomers {
      customer_name
      added_by
    }
  }
`;
export const getSellingMarks = /* GraphQL */ `
  query GetSellingMarks {
    getSellingMarks {
      selling_mark
    }
  }
`;
export const getBrokers = /* GraphQL */ `
  query GetBrokers {
    getBrokers {
      broker_name
      broker_code
      broker_url
    }
  }
`;
export const getPostAuctionReports = /* GraphQL */ `
  query GetPostAuctionReports(
    $fields: [FieldComparator]
    $sortfields: String
    $aggregationfields: String
    $currency: String
    $limit: Int
    $offset: Int
  ) {
    getPostAuctionReports(
      fields: $fields
      sortfields: $sortfields
      aggregationfields: $aggregationfields
      currency: $currency
      limit: $limit
      offset: $offset
    ) {
      grade
      lot_no
      year
      net_weight
      broker
      asking_price
      selling_mark
      highest_bid
      total_weight
      total_price
      sale_code
      invoice_no
      buyer
    }
  }
`;
export const getPostAuctionReportsGroupByBuyer = /* GraphQL */ `
  query GetPostAuctionReportsGroupByBuyer(
    $fields: [FieldComparator]
    $currency: String
    $sortfields: String
  ) {
    getPostAuctionReportsGroupByBuyer(
      fields: $fields
      currency: $currency
      sortfields: $sortfields
    ) {
      grade
      lot_no
      year
      net_weight
      broker
      asking_price
      selling_mark
      highest_bid
      total_weight
      total_price
      sale_code
      invoice_no
      buyer
    }
  }
`;
export const getSummarizedPostAuctionReports = /* GraphQL */ `
  query GetSummarizedPostAuctionReports(
    $fields: [FieldComparator]
    $sortfields: String
    $summarizecolumn: [String]!
    $currency: String
  ) {
    getSummarizedPostAuctionReports(
      fields: $fields
      sortfields: $sortfields
      summarizecolumn: $summarizecolumn
      currency: $currency
    ) {
      min
      max
      avg
      firstQuater
      secondQuater
    }
  }
`;
export const getUploadedFiles = /* GraphQL */ `
  query GetUploadedFiles {
    getUploadedFiles {
      request_id
      request_date_time
      file_name
      status
      description
    }
  }
`;
export const getCurrencies = /* GraphQL */ `
  query GetCurrencies {
    getCurrencies {
      currency
    }
  }
`;
export const getCurrencyHistory = /* GraphQL */ `
  query GetCurrencyHistory(
    $fields: [FieldComparator]
    $limit: Int
    $offset: Int
  ) {
    getCurrencyHistory(fields: $fields, limit: $limit, offset: $offset) {
      from_currency
      to_currency
      received_date
      price
    }
  }
`;

export const getWeatherSeries = /* GraphQL */ `
  query GetWeatherSeriesData(
    $location: String
    $startDate: String
    $endDate: String
  ) {
    getWeatherSeriesData(location: $location, startDate: $startDate, endDate: $endDate) {
      code
      msg
      result
    }
  }
`;
export const isRequestAuthorized = /* GraphQL */ `
  query IsRequestAuthorized($authorizationToken: String) {
    isRequestAuthorized(authorizationToken: $authorizationToken) {
      isAuthorized
    }
  }
`;
export const intelligentfilter = /* GraphQL */ `
  query Intelligentfilter($dependencies: filterCriteria!) {
    intelligentfilter(dependencies: $dependencies) {
      key
      value
    }
  }
`;
export const getTeaTalkJobs = /* GraphQL */ `
  query GetTeaTalkJobs($batchId: Int, $batchSize: Int) {
    getTeaTalkJobs(batchId: $batchId, batchSize: $batchSize) {
      status
      jobs {
        id
        start_timestamp
        end_timestamp
        audio_s3_uri
        txt_s3_uri
        csv_s3_uri
        status
        user_id
        pdf_s3_uri
        description
        broker_name
        sale_type
        year
        sale_code
        trader_name
        starting_lot
        ending_lot
      }
      arguments
    }
  }
`;
export const startTeaTalkJobAudio2Text = /* GraphQL */ `
  query StartTeaTalkJobAudio2Text(
    $audioS3Uri: String!
    $fileDetails: AWSJSON!
  ) {
    startTeaTalkJobAudio2Text(
      audioS3Uri: $audioS3Uri
      fileDetails: $fileDetails
    ) {
      jobId
      status
      arguments
    }
  }
`;
export const getSimilarityMatches = /* GraphQL */ `
  query GetSimilarityMatches($request: SimilarityMatchingRequest) {
    getSimilarityMatches(request: $request)
  }
`;
export const getTeaQuotation = /* GraphQL */ `
  query GetTeaQuotation($request: SimilarityMatchingRequest) {
    getTeaQuotation(request: $request)
  }
`;
export const getSimilarityMatchesLotMode = /* GraphQL */ `
  query GetSimilarityMatchesLotMode($request: SimilarityMatchingRequest) {
    getSimilarityMatchesLotMode(request: $request)
  }
`;
export const getStandards = /* GraphQL */ `
  query GetStandards {
    getStandards {
      std_name
      std_category
      std_status
      std_path
      user_name
      user_group
      std_customer
    }
  }
`;
export const getStandardQueryHistory = /* GraphQL */ `
  query GetStandardQueryHistory(
    $standard: String
    $category: String
    $limit: Int
    $offset: Int
  ) {
    getStandardQueryHistory(
      standard: $standard
      category: $category
      limit: $limit
      offset: $offset
    )
  }
`;
export const getWeatherData = /* GraphQL */ `
  query GetWeatherData(
    $location: String
    $startDate: String
    $endDate: String
  ) {
    getWeatherData(
      location: $location
      startDate: $startDate
      endDate: $endDate
    ) {
      code
      msg
      result
    }
  }
`;
export const getAvgWeatherData = /* GraphQL */ `
  query GetAvgWeatherData(
    $regionName: String
    $startDate: String
    $endDate: String
  ) {
    getAvgWeatherData(
      regionName: $regionName
      startDate: $startDate
      endDate: $endDate
    ) {
      code
      msg
      result
    }
  }
`;
export const postConfigurationsData = /* GraphQL */ `
  query PostConfigurationsData(
    $user_email: String
    $config_name: String
    $config_file: String
  ) {
    postConfigurationsData(
      user_email: $user_email
      config_name: $config_name
      config_file: $config_file
    ) {
      code
      msg
      result
    }
  }
`;
export const getConfiguraionsData = /* GraphQL */ `
  query GetConfiguraionsData($user_email: String, $config_name: String) {
    getConfiguraionsData(user_email: $user_email, config_name: $config_name) {
      code
      msg
      result
    }
  }
`;
export const getWeatherSeriesData = /* GraphQL */ `
  query GetWeatherSeriesData(
    $location: String
    $startDate: String
    $endDate: String
  ) {
    getWeatherSeriesData(
      location: $location
      startDate: $startDate
      endDate: $endDate
    ) {
      code
      msg
      result
    }
  }
`;
export const getFactoryNames = /* GraphQL */ `
  query GetFactoryNames {
    getFactoryNames {
      code
      msg
      result
    }
  }
`;
export const getAvgRainfallData = /* GraphQL */ `
  query GetAvgRainfallData($startDate: String, $endDate: String) {
    getAvgRainfallData(startDate: $startDate, endDate: $endDate) {
      code
      msg
      result
    }
  }
`;
export const getWeatherIconData = /* GraphQL */ `
  query GetWeatherIconData($startDate: String, $endDate: String) {
    getWeatherIconData(startDate: $startDate, endDate: $endDate) {
      code
      msg
      result
    }
  }
`;
export const getResultsByArgs = /* GraphQL */ `
  query GetResultsByArgs(
    $columns: [String]
    $table: String
    $fields: [FieldComparator]
    $sortfields: String
    $limit: Int
    $offset: Int
  ) {
    getResultsByArgs(
      columns: $columns
      table: $table
      fields: $fields
      sortfields: $sortfields
      limit: $limit
      offset: $offset
    )
  }
`;
export const getForecastedValues = /* GraphQL */ `
  query GetForecastedValues(
    $data: [Float!]!
    $forecast_points: Int!
    $validation_points: Int
    $validation_nmae: Float
  ) {
    getForecastedValues(
      data: $data
      forecast_points: $forecast_points
      validation_points: $validation_points
      validation_nmae: $validation_nmae
    ) {
      code
      status
      result
    }
  }
`;
export const getHeatMapData = /* GraphQL */ `
  query GetHeatMapData(
    $fields: [FieldComparator]
    $sortfields: String
    $aggregationfields: String
    $currency: String
    $limit: Int
    $offset: Int
  ) {
    getHeatMapData(
      fields: $fields
      sortfields: $sortfields
      aggregationfields: $aggregationfields
      currency: $currency
      limit: $limit
      offset: $offset
    ) {
      location
      average_price
      year
      sale_code
    }
  }
`;
export const getemaillogo = /* GraphQL */ `
  query Getemaillogo {
    getemaillogo {
      company_email
      company_logo_path
      company_address
      phone
      fax
    }
  }
`;
export const getquotationdata = /* GraphQL */ `
  query Getquotationdata {
    getquotationdata {
      quotation_id
      requested_date
      customer_name
      quotation_status
      b_per_kilo_price
      b_moq
      b_max
      b_description
    }
  }
`;
export const getPrivileges = /* GraphQL */ `
  query GetPrivileges($roles: [String!]) {
    getPrivileges(roles: $roles)
  }
`;
export const getPages = /* GraphQL */ `
  query GetPages {
    getPages
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles {
    getRoles
  }
`;
export const getAllCustomers = /* GraphQL */ `
  query GetAllCustomers {
    getAllCustomers
  }
`;
export const getAuditRecords = /* GraphQL */ `
  query GetAuditRecords {
    getAuditRecords {
      code
      msg
      result
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($group_name: String) {
    getGroup(group_name: $group_name) {
      code
      msg
      result
    }
  }
`;
