import { FC, useContext } from 'react';
import { UserManagementContext } from "src/content/applications/Users/UserManagementProvider";

interface PropType {
    component: JSX.Element;
    categoryname: String;
    role: String;
}

const HideRoute: FC<PropType> = ({ component: Component, categoryname: categoryname, role: role }) => {

  const context = useContext(UserManagementContext);
  const { privileges } = context;

  const privilege = privileges.filter(ele => ele.functionality === categoryname);
  if (privilege.length !== 0) {
    const privilege_1 = privilege[0];
    if (privilege_1.privilege_name === 'view' || privilege_1.privilege_name === 'edit') {
      return Component;
    }
  }
  
  return <></>;
};

export default HideRoute;
