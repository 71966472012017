const styles = {
    backdrop: {
        position: "absolute",
        width: "100%",
        height: "100%",
        maxHeight: "80vh",
        backdropilter: "blur(2px)",
        left: "0px",
        top: "0px",
        zIndex: 10000000000000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modal: {
        backgroundColor: (theme) => theme.colors.alpha.trueWhite[100],
        borderRadius: "10px",
        boxShadow: "0px 0px 10px #0002",
        padding: "20px",
        marginRight: "100px",
        marginLeft: "100px",


    },
    head: {
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    cross: {
        position: "absolute",
        right: "0px",
        cursor: "pointer",
    },
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "600px",
    },
};

export default styles;
