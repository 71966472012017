import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import ProtectedRoute from "./ProtectedRoute";
import PageTitleObject from "./components/PageTitleObject/PageTitleObject";
import ModalProvider from "src/components/modalProvider/ModalProvider";

import { TEAAI_S3_BUCKET } from "src/constants";

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Pages

// Dashboards
const CompetitorAnalysis = Loader(
    lazy(() => import("src/content/dashboards/competitor-analysis"))
);
const PriceHistory = Loader(
    lazy(() => import("src/content/dashboards/price-history"))
);
const ExchangeRate = Loader(
    lazy(() => import("src/content/dashboards/exchange-rates"))
);
const WeatherAnalytics = Loader(
    lazy(() => import("src/content/dashboards/weather-analytics"))
);
const HeatMap = Loader(
    lazy(() => import("src/content/dashboards/heat-map"))
);
const Demo = Loader(
    lazy(() => import("src/content/dashboards/demo"))
);
const WeatherDemo = Loader(
    lazy(() => import("src/content/dashboards/demo/weather"))
);
// Applications
const UserManagement = Loader(
    lazy(() => import("src/content/applications/Users"))
);

const UserRoleManagement = Loader(
    lazy(() => import("src/content/applications/Users/Privileges"))
);

const UserGroupManagement = Loader(
    lazy(() => import("src/content/applications/Users/Groups"))
);

const UserCreation = Loader(
    lazy(() => import("src/content/applications/Users/Registration"))
);

const SignIn = Loader(lazy(() => import("src/content/signin/Signin")));

const SignUp = Loader(lazy(() => import("src/content/signin/Signup")));

const ForgotPassword = Loader(
    lazy(() => import("src/content/signin/ForgotPassword"))
);

const VerifyUser = Loader(lazy(() => import("src/content/signin/VerifyUser")));

const ResetUser = Loader(lazy(() => import("src/content/signin/ResetUser")));

const UserProfile = Loader(
    lazy(() => import("src/content/applications/Users/profile"))
);
const UserSettings = Loader(
    lazy(() => import("src/content/applications/Users/settings"))
);

const FileUpload = Loader(
    lazy(() => import("src/content/applications/Fileupload"))
);

const FileImport = Loader(
    lazy(() => import("src/content/applications/MarketDataFileImport"))
);

const FileExport = Loader(
    lazy(() => import("src/content/applications/MarketDataFileExport"))
);

const FileUploadTask = Loader(
    lazy(() => import("src/content/applications/FileuploadTask"))
);
const AuditTable = Loader(
    lazy(() => import("src/content/applications/Audit"))
);

const TeaTalk = Loader(lazy(() => import("src/content/applications/TeaTalk")));

const TeaRetinaCapture = Loader(lazy(() => import("src/content/applications/TeaRetina/capture")));
const TeaRetinaHistory = Loader(lazy(() => import("src/content/applications/TeaRetina/history")));
const TeaRetinaManage = Loader(lazy(() => import("src/content/applications/TeaRetina/manage")));
const TeaRetinaMatch = Loader(lazy(() => import("src/content/applications/TeaRetina/match")));
const TeaRetinaMatches = Loader(lazy(() => import("src/content/applications/TeaRetina/match/standard")));
const TeaRetinaLotMode = Loader(lazy(() => import("src/content/applications/TeaRetina/match/lotmode")));
const TeaRetinaHistories = Loader(lazy(() => import("src/content/applications/TeaRetina/history/view")));
const TeaRetinaCatpured = Loader(lazy(() => import("src/content/applications/TeaRetina/captured")));
const TeaRetinaLotModeView = Loader(lazy(() => import("src/content/applications/TeaRetina/match/lotmode/view")));

//Tea Quotations
const TeaQuotationRequest = Loader(lazy(() => import("src/content/applications/TeaQuotation")));

//Customers
const CustomerRegister = Loader(lazy(() => import("src/content/applications/Customers/Registration")));
const CustomersTable = Loader(lazy(() => import("src/content/applications/Customers/view/")));

//Settings
const SettingsPage = Loader(lazy(() => import("src/content/pages/SettingsPage")));

// Status
const Status404 = Loader(
    lazy(() => import("src/content/pages/Status/Status404"))
);
const Status500 = Loader(
    lazy(() => import("src/content/pages/Status/Status500"))
);
const StatusComingSoon = Loader(
    lazy(() => import("src/content/pages/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
    lazy(() => import("src/content/pages/Status/Maintenance"))
);

const routes: RouteObject[] = [
    {
        path: "",
        element: <BaseLayout />,
        children: [
            {
                path: "/",
                element: <SignIn />,
            },
            {
                path: "/signin",
                element: <Navigate to="/" replace />,
            },
            {
                path: "/forgotpassword",
                element: <ForgotPassword />,
            },
            {
                path: "/verifyuser",
                element: <VerifyUser />,
            },
            {
                path: "/resetuser",
                element: <ResetUser />,
            },
            {
                path: "status",
                children: [
                    {
                        path: "",
                        element: <Navigate to="404" replace />,
                    },
                    {
                        path: "404",
                        element: <Status404 />,
                    },
                    {
                        path: "500",
                        element: <Status500 />,
                    },
                    {
                        path: "maintenance",
                        element: <StatusMaintenance />,
                    },
                    {
                        path: "coming-soon",
                        element: <StatusComingSoon />,
                    },
                ],
            },
            {
                path: "*",
                element: <Status404 />,
            },
        ],
    },
    {
        path: "dashboards",
        element: <SidebarLayout header={'TeaMate'} />,
        children: [
            {
                path: "",
                element: <Navigate to="competitor-analysis" replace />,
            },
            {
                path: "competitor-analysis",
                element: (
                    <ProtectedRoute
                        component={<CompetitorAnalysis />}
                        name={"CompetitorAnalysis"}
                    />
                ),
            },
            {
                path: "price-history",
                element: (
                    <ProtectedRoute
                        component={<PriceHistory />}
                        name={"PriceHistory"}
                    />
                ),
            },
            {
                path: "exchange-rates",
                element: (
                    <ProtectedRoute
                        component={<ExchangeRate />}
                        name={"ExchangeRate"}
                    />
                ),
            },
            {
                path: "weather-analytics",
                element: (
                    <ProtectedRoute
                        component={<WeatherAnalytics />}
                        name={"WeatherAnalytics"}
                    />
                ),
            },
            {
                path: "heat-map",
                element: (
                    <ProtectedRoute
                        component={<HeatMap />}
                        name={"HeatMap"}
                    />
                ),
            },
            {
                path: "*",
                element: <Status404 />,
            },
        ],
    },
    {
        path: "file-management",
        element: <SidebarLayout header={'General'} />,
        children: [
            {
                path: "catalogs",
                element: (
                    <ProtectedRoute
                        component={
                            <FileUpload
                                props={{
                                    title: "catalog",
                                    tableName: "ai.auction_catalog",
                                    keyPath: "catalogs",
                                    bucketName: TEAAI_S3_BUCKET,
                                    api: TEAAI_S3_BUCKET,
                                    apiPath: "auction-catalog",
                                    mapper: [
                                        {
                                            Source: "Year",
                                            DType: "int",
                                            Destination: "year",
                                        },
                                        {
                                            Source: "Sale Code",
                                            DType: "int",
                                            Destination: "sale_code",
                                        },
                                        {
                                            Source: "Broker Code",
                                            DType: "string",
                                            Destination: "broker_code",
                                        },
                                        {
                                            Source: "Broker",
                                            DType: "string",
                                            Destination: "broker",
                                        },
                                        {
                                            Source: "Category",
                                            DType: "string",
                                            Destination: "category",
                                        },
                                        {
                                            Source: "Factory",
                                            DType: "int",
                                            Destination: "factory",
                                        },
                                        {
                                            Source: "Selling Mark - MF Mark",
                                            DType: "string",
                                            Destination: "selling_mark",
                                        },
                                        {
                                            Source: "Lot No",
                                            DType: "int",
                                            Destination: "lot_no",
                                        },
                                        {
                                            Source: "Reprint",
                                            DType: "string",
                                            Destination: "re_print",
                                        },
                                        {
                                            Source: "Bags",
                                            DType: "string",
                                            Destination: "bags",
                                        },
                                        {
                                            Source: "Net Weight",
                                            DType: "int",
                                            Destination: "net_weight",
                                        },
                                        {
                                            Source: "Grade",
                                            DType: "string",
                                            Destination: "grade",
                                        },
                                        {
                                            Source: "Invoice No",
                                            DType: "string",
                                            Destination: "invoice_no",
                                        },
                                        {
                                            Source: "Rainforest",
                                            DType: "string",
                                            Destination: "rain_forest",
                                        },
                                        {
                                            Source: "Certifications",
                                            DType: "string",
                                            Destination: "certificate_names",
                                        },
                                        {
                                            Source: "Sale Date",
                                            DType: "string",
                                            Destination: "sale_date",
                                        },
                                        {
                                            Source: "Total Weight",
                                            DType: "int",
                                            Destination: "total_weight",
                                        },
                                    ],
                                }}
                            />
                        }
                        name={"FileUpload"}
                    />
                ),
            },
            {
                path: "post-auction-reports",
                element: (
                    <ProtectedRoute
                        component={
                            <FileUpload
                                props={{
                                    title: "post auction report",
                                    tableName: "ai.auction_report",
                                    keyPath: "auction-reports",
                                    bucketName: TEAAI_S3_BUCKET,
                                    api: TEAAI_S3_BUCKET,
                                    apiPath: "auction-report",
                                    mapper: [
                                        { 
                                            Source: "Selling End Time", 
                                            DType: "int", 
                                            Destination: "year", 
                                        },
                                        { 
                                            Source: "Sale Code", 
                                            DType: "int", 
                                            Destination: "sale_code",
                                        },
                                        { 
                                            Source: "Broker", 
                                            DType: "string", 
                                            Destination: "broker",
                                        },
                                        { 
                                            Source: "Category", 
                                            DType: "string", 
                                            Destination: "category",
                                        },
                                        { 
                                            Source: "Lot No", 
                                            DType: "int", 
                                            Destination: "lot_no", 
                                        },
                                        { 
                                            Source: "RP", 
                                            DType: "string", 
                                            Destination: "re_print", 
                                        },
                                        { 
                                            Source: "RA", 
                                            DType: "string", 
                                            Destination: "certified", 
                                        },
                                        { 
                                            Source: "Selling Mark", 
                                            DType: "string", 
                                            Destination: "selling_mark", 
                                        },
                                        { 
                                            Source: "Grade", 
                                            DType: "string", 
                                            Destination: "grade", 
                                        },
                                        { 
                                            Source: "Invoice No", 
                                            DType: "string", 
                                            Destination: "invoice_no", 
                                        },
                                        { 
                                            Source: "Bags", 
                                            DType: "string", 
                                            Destination: "bags", 
                                        },
                                        { 
                                            Source: "Net Weight", 
                                            DType: "int", 
                                            Destination: "net_weight", 
                                        },
                                        { 
                                            Source: "Total Weight", 
                                            DType: "int", 
                                            Destination: "total_weight", 
                                        },
                                        { 
                                            Source: "Asking Price", 
                                            DType: "int", 
                                            Destination: "asking_price", 
                                        },
                                        { 
                                            Source: "Total Price", 
                                            DType: "int", 
                                            Destination: "total_price", 
                                        },
                                        { 
                                            Source: "Status", 
                                            DType: "string", 
                                            Destination: "status", 
                                        },
                                        { 
                                            Source: "Buyer", 
                                            DType: "string", 
                                            Destination: "buyer", 
                                        },
                                        { 
                                            Source: "Buyer Name", 
                                            DType: "string", 
                                            Destination: "buyer_name", 
                                        },
                                        {
                                          Source: "Buyer User Name",
                                          DType: "string",
                                          Destination: "buyer_user_name",
                                        },
                                        { 
                                            Source: "Amount.1", 
                                            DType: "int", 
                                            Destination: "highest_bid", 
                                        },
                                      ],
                                }}
                            />
                        }
                        name={"FileUpload"}
                    />
                ),
            },
            {
                path: "market-report/import",
                element: (
                    <ProtectedRoute
                        component={
                            <FileImport
                                props={{
                                    bucketName: TEAAI_S3_BUCKET,
                                    keyPath: "market data",
                                    apiPath: "market-report",
                                }}
                            />
                        }
                        name={"FileImport"}
                    />
                ),
            },
            {
                path: "market-report/export",
                element: (
                    <ProtectedRoute
                        component={
                            <FileExport
                                props={{
                                    bucketName: TEAAI_S3_BUCKET,
                                    keyPath: "market data",
                                    apiPath: "market-report",
                                }}
                            />
                        }
                        name={"FileExport"}
                    />
                ),
            },
            {
                path: "*",
                element: <Status404 />,
            },
        ],
    },
    {
        path: "artificial-intelligence",
        element: <SidebarLayout header={'TeaTalk'} />,
        children: [
            {
                path: "",
                element: <Navigate to="teatalk" replace />,
            },
            {
                path: "teatalk",
                element: (
                    <ProtectedRoute
                        component={
                            <TeaTalk PageTitleObject={PageTitleObject} />
                        }
                        name={"TeaTalk"}
                    />
                ),
            },
            {
                path: "*",
                element: <Status404 />,
            },
        ],
    },
    {
        path: "user-management",
        element: <SidebarLayout header={'General'} />,
        children: [
            {
                path: "view",
                element: (
                    <ProtectedRoute
                        component={<ModalProvider><UserManagement /></ModalProvider>}
                        name={"UserManagement"}
                    />
                ),
            },
            {
                path: "role-management",
                element: (
                    <ProtectedRoute
                        component={<ModalProvider><UserRoleManagement /></ModalProvider>}
                        name={"UserRoleManagement"}
                    />
                ),
            },
            {
                path: "group-management",
                element: (
                    <ProtectedRoute
                        component={<ModalProvider><UserGroupManagement /></ModalProvider>}
                        name={"UserGroupManagement"}
                    />
                ),
            },
            {
                path: "register-user",
                element: (
                    <ProtectedRoute
                        component={<ModalProvider><UserCreation /></ModalProvider>}
                        name={"UserCreation"}
                    />
                ),
            },
            {
                path: "*",
                element: <Status404 />,
            },
        ],
    },
    {
        path: "management",
        element: <SidebarLayout header={'General'} />,
        children: [
            {
                path: "profile",
                children: [
                    {
                        path: "",
                        element: <Navigate to="details" replace />,
                    },
                    {
                        path: "details",
                        element: <UserProfile />,
                    },
                    {
                        path: "settings",
                        element: <UserSettings />,
                    },
                    {
                        path: "*",
                        element: <Status404 />,
                    },
                ],
            },
        ],
    },
    {
      path: "tearetina",
      element: <SidebarLayout header={'TeaRetina'} />,
      children: [
        {
          path: "capture-standards",
          element: (
            <ProtectedRoute
              component={<TeaRetinaCapture />}
              name={"TeaRetinaCapture"}
            />
          ),
        },
        {
          path: "manage-standards",
          element: (
            <ProtectedRoute component={<TeaRetinaManage />} name={"TeaRetinaManage"} />
          ),
        },
        {
          path: "match-standards",
          element: (
            <ProtectedRoute component={<TeaRetinaMatch />} name={"TeaRetinaMatch"} />
          ),
        },
        {
          path: "match-history",
          element: (
            <ProtectedRoute component={<TeaRetinaHistory />} name={"TeaRetinaHistory"} />
          ),
        },
        {
          path: "*",
          element: <Status404 />,
        },
      ],
    },
    {
        path: "tea-quotation",
        element: <SidebarLayout header={'Tea Quotation'} />,
        children: [
        {
          path: "request",
          element: (
            <ProtectedRoute
              component={<TeaQuotationRequest />}
              name={"TeaQuotationRequest"}
            />
          ),
        },
        ]
    },
    {
        path: "customers",
        element: <SidebarLayout header={'Customers Management'} />,
        children: [
        {
          path: "register",
          element: (
            <ProtectedRoute
              component={<CustomerRegister />}
              name={"CustomerRegister"}
            />
          ),
        },
        {
            path: "view",
            element: (
              <ProtectedRoute
                component={<ModalProvider><CustomersTable /></ModalProvider>}
                name={"CustomersTable"}
              />
            ),
          },
        ]
    },
    {
      path: "tearetina/match-standards",
      element: <SidebarLayout header={'TeaRetina'} />,
      children: [
        {
            path: "view-matches",
            element: (
              <ProtectedRoute component={<ModalProvider><TeaRetinaMatches /></ModalProvider>} name={"TeaRetinaMatches"} />
            ),
        },
        {
          path: "lot-mode",
          element: (
            <ProtectedRoute component={<TeaRetinaLotMode />} name={"TeaRetinaLotMode"} />
          ),
        },
        {
            path: "lot-mode/view",
            element: (
              <ProtectedRoute component={<ModalProvider><TeaRetinaLotModeView /></ModalProvider>} name={"TeaRetinaLotModeView"} />
            ),
        },
    ]
    },
    {
      path: "tearetina/match-history",
      element: <SidebarLayout header={'TeaRetina'} />,
      children: [
        {
            path: "view",
            element: (
              <ProtectedRoute component={<TeaRetinaHistories />} name={"TeaRetinaHistories"} />
            ),
        }]
    },
    {
      path: "tearetina/capture-standards",
      element: <SidebarLayout header={'TeaRetina'} />,
      children: [
        {
            path: "capture",
            element: (
              <ProtectedRoute component={<TeaRetinaCatpured />} name={"TeaRetinaCatpured"} />
            ),
        }]
    },
    {
      path: "user-management",
      element: <SidebarLayout header={'General'} />,
      children: [
        {
          path: "view",
          element: (
            <ProtectedRoute
              component={<ModalProvider><UserManagement /></ModalProvider>}
              name={"UserManagement"}
            />
          ),
        },
        {
            path: "role-management",
            element: (
                <ProtectedRoute
                    component={<ModalProvider><UserRoleManagement /></ModalProvider>}
                    name={"UserRoleManagement"}
                />
            ),
        },
        {
            path: "group-management",
            element: (
                <ProtectedRoute
                    component={<ModalProvider><UserGroupManagement /></ModalProvider>}
                    name={"UserGroupManagement"}
                />
            ),
        },
        {
          path: "register-user",
          element: (
            <ProtectedRoute component={<ModalProvider><UserCreation /></ModalProvider>} name={"UserCreation"} />
          ),
        },
        {
          path: "*",
          element: <Status404 />,
        },
      ],
    },
    {
        path: "audit",
        element: <SidebarLayout />,
        children: [
            {
                path: "upload-tasks",
                element: (
                    <ProtectedRoute
                        component={<FileUploadTask />}
                        name={"FileUploadTask"}
                    />
                ),
            },
            {
                path: "user-actions",
                element: (
                    <ProtectedRoute
                        component={<AuditTable />}
                        name={"AuditTable"}
                    />
                ),
            },
            {
                path: "*",
                element: <Status404 />,
            },
        ],
    },
    {
      path: "management",
      element: <SidebarLayout header={'Management'} />,
      children: [
        {
            path: "settings",
            element: <ProtectedRoute component={<SettingsPage />} name={"SettingsPage"} />,
        },
        {
          path: "profile",
          children: [
            {
              path: "",
              element: <Navigate to="details" replace />,
            },
            {
              path: "details",
              element: <UserProfile />,
            },
            {
              path: "user-settings",
              element: <UserSettings />,
            },
            {
              path: "*",
              element: <Status404 />,
            },
          ],
        },
      ],
    },
    {
      path: "demo",
      element: 
        <ProtectedRoute
            component={<Demo />}
            name={"Demo"}
        />
    },
    {
      path: "demo/weather",
      element:
            <ProtectedRoute
                component={<WeatherDemo />}
                name={"WeatherDemo"}
            />
    },
    {
      path: "/health",
      element: <h1>passed health</h1>,
    }
];

export default routes;
