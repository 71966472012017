import { FC, useEffect, useState, useContext } from "react";
import { signOut } from "./Utils";
import { Auth } from "aws-amplify";
import Status404 from "./content/pages/Status/Status404";
import { useNavigate } from "react-router-dom";
import { UserManagementContext } from "src/content/applications/Users/UserManagementProvider";
import { getPrivilegeList } from 'src/Utils';

interface PropType {
  component: JSX.Element;
  name: String;
}

const ProtectedRoute: FC<PropType> = ({ component: Component, name: name }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [privReLoading, setPrivilegeReLoading] = useState(false); // Loading state
  const [hasPrivilege, setHasPrivilege] = useState(false); // Privilege state

  const context = useContext(UserManagementContext);
  const { privileges, setPrivileges } = context;

  useEffect(() => {
    const sessionMgt = async () => {
      try {
        const session = await Auth.currentSession();
        const decodedToken = session.getIdToken().decodePayload();
        const epochNow = Math.floor(Date.now() / 1000);
        const diff = decodedToken.exp - epochNow;
        if (diff < 0) {
          signOut(navigate);
        }
      } catch (err) {
        signOut(navigate);
      }
 
      if (privileges.length === 0) {
        Auth.currentAuthenticatedUser().then((user) => {
            const privileges = getPrivilegeList({roles: [user.attributes['custom:role']]});
            privileges.then( (privilegeList) => {
                setPrivileges(JSON.parse(privilegeList['data'].getPrivileges));
                setPrivilegeReLoading(true);
            });
        })
      }
      const privilege = privileges.filter(ele => ele.functionality === name);
      if (privilege.length !== 0) {
        const privilege_1 = privilege[0];
        if (privilege_1.privilege_name === 'view' || privilege_1.privilege_name === 'edit') {
          setHasPrivilege(true);
        }
        setIsLoading(false);
      }
    };
    if (isLoading) {
      sessionMgt();
    }
  }, [name, privReLoading]);

  if (isLoading) {
    return <div>Loading...</div>; // Loading indicator while checking privileges
  }

  if (!hasPrivilege) {
    return <Status404 />; // Render 404 if no privilege found
  }

  return Component; // Render the actual component if privileges are valid
};

export default ProtectedRoute;
