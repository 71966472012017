import PageTitle from "../PageTitle";
import PageTitleWrapper from "../PageTitleWrapper";

const PageTitleObject = ({ heading, subHeading, docs }) => {
    return (
        <PageTitleWrapper>
            <PageTitle heading={heading} subHeading={subHeading} docs={docs} />
        </PageTitleWrapper>
    );
};

export default PageTitleObject;
