import { API, Auth } from "aws-amplify";
import {
    listUserRoles,
    getBrokers,
    getBuyers,
    getSellingMarks,
    getCategory,
    getGrades,
    listUsers,
    getUploadedFiles,
    getCurrencies,
    intelligentfilter,
    getStandards,
    getSimilarityMatches,
    getStandardQueryHistory,
    getSimilarityMatchesLotMode,
    getWeatherData,
    getAvgWeatherData,
    getFactoryNames,
    getWeatherSeries,
    postConfigurationsData,
    getConfiguraionsData,
    getWeatherIconData,
    getHeatMapData,
    getPrivileges,
    getPages,
    getRoles,
    getAuditRecords,
    getGroup
} from "src/graphql/queries";
import config from "src/graphql-exports";
import {
    uploadFile,
    processFile,
    createUser,
    deleteStandard,
    editStandard,
    deleteStandardHistory,
    deleteAllStandardHistory,
    insertStandardHistory,
    insertStandard,
    updatePrivileges,
    enableUser,
    disableUser,
    updateAttributes,
    deleteUser,
    deleteRole,
    addAudit,
    insertGroup,
    deleteGroup
} from "src/graphql/mutations";
import { Option } from "src/components/OptionChecker";
import {
    FilterCriteria,
    IntelligentFilterResponse,
} from "src/content/dashboards/TypeDefs";
import { FilterDefinition } from "src/content/dashboards/TypeDefs";
import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

API.configure(config);

export async function loadAllUserRoles() {
    const session = await Auth.currentSession();
    return API.graphql({
        query: listUserRoles,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function loadBrokers() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getBrokers,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function loadBuyers() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getBuyers,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function loadSellingMarks() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getSellingMarks,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function loadCategories() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getCategory,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function loadGrades() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getGrades,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getUsers() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: listUsers,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getPrivilegeList(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getPrivileges,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getPageList() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getPages,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function updatePrivilegeList(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: updatePrivileges,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getRoleList() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getRoles,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function adminDisableUser(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: disableUser,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function adminEnableUser(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: enableUser,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function adminUpdateAttributes(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: updateAttributes,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function adminDeleteUser(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: deleteUser,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function adminDeleteRoles(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: deleteRole,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function fileUpload(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: uploadFile,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function fileProcess(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: processFile,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function listUploadedFiles() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getUploadedFiles,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function registerUser(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: createUser,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function filterValues(queryStr, params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: queryStr,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function loadCurrencies() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getCurrencies,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getWeatherInfo(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getWeatherData,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function postAudit(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: addAudit,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getAudit() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getAuditRecords,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function hashJWT(jwtToken) {
    const encoder = new TextEncoder();
    const data = encoder.encode(jwtToken);
  
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export async function getAvgWeatherInfo(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getAvgWeatherData,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function postConfiguraions(params){
    const session = await Auth.currentSession();
    return await API.graphql({
        query: postConfigurationsData,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getConfiguraions(params){
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getConfiguraionsData,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getFactories() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getFactoryNames,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getWeatherIcon(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getWeatherIconData,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getHeatInfo(params) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getHeatMapData,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getWeatherSeriesData(params){
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getWeatherSeries,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function signOut(nav) {
    try {
        const session = await Auth.currentSession();
        const session_id = await hashJWT(session.getIdToken().getJwtToken());
        const user = await Auth.currentAuthenticatedUser();
        await postAudit({audit: {event_type: "SignOut",
            event_description: 'User ' + user['attributes']['name'] + ' signed out.',
            status: "Completed",
            initiated_by: user['attributes']['email'],
            event_id: uuid(),
            session_id: session_id,
            initiated_at: new Date().toISOString()}});
    } catch (error) {
        console.log("error signing out: ", error);
    } finally {
        await Auth.signOut();
        nav("/");
    }
}

export async function loadIntelligentFilter(params: FilterCriteria) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: intelligentfilter,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getTeaStandards() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getStandards,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function deleteTeaStandards(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: deleteStandard,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function editTeaStandards(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: editStandard,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function matchStandard(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getSimilarityMatches,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function matchStandardLotMode(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getSimilarityMatchesLotMode,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getmatchStandardHistory(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getStandardQueryHistory,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function deleteMatchStandardHistory(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: deleteStandardHistory,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function deleteAllMatchStandardHistory() {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: deleteAllStandardHistory,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function insertMatchStandardHistory(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: insertStandardHistory,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function insertStandards(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: insertStandard,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function deleteUserGroup(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: deleteGroup,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function createUserGroup(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: insertGroup,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export async function getUserGroups(params: any) {
    const session = await Auth.currentSession();
    return await API.graphql({
        query: getGroup,
        variables: params,
        authToken: session.getIdToken().getJwtToken(),
    });
}

export function getUserStandardsMap(standards, groups, current_user) {
    
    const userStandardsFromDirect = standards.filter(
        (std) => std.user_name === current_user['attributes']['name']
    );

    const user_map = groups.reduce((userStandardsMap, group) => {
        group.user_list.forEach((user) => {
            const userStandardsFromGroup = standards.filter(
                (std) => 
                    current_user['attributes']['name'] === user && std.user_group === group.group_name
            );

            if (userStandardsFromGroup.length > 0) {
                userStandardsMap[user] = userStandardsFromGroup;
            }

            if (userStandardsFromDirect.length > 0) {
                const tmp = userStandardsMap[user] || []
                userStandardsMap[user] = [...tmp, userStandardsFromGroup];
            }
        });
        return userStandardsMap;
    }, {});
    return user_map;
}

export function convertToOption(array: IntelligentFilterResponse[]) {
    return array.map((ele) => {
        return { label: ele.value, value: ele.value };
    });
}

export async function createFile(url : string, name : string) {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };
    let file = new File([data], name + ".jpg", metadata);
    return file;
}

export const loadYears = () => {
    const year = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 7; i++) {
      years.push((year-i).toString());
    }
    return years;
  }

export const loadSaleCodes = () => {
    const weeks = [];
    for (let i = 1; i < parseInt(process.env.REACT_APP_SALE_CODE_MAX); i++) {
      weeks.push((i).toString());
    }
    return weeks;
  }


export function getGraphKeys(array: any) {
    console.log(array);
    let r = [],
        max = array.length - 1;
    function helper(arr, i) {
        for (var j = 0, l = array[i].length; j < l; j++) {
            var a = arr.slice(0);
            a.push(array[i][j]);
            if (i == max) r.push(a);
            else helper(a, i + 1);
        }
    }
    helper([], 0);
    return r;
}

export const genSpaces = (num: number) => {
    return " ".repeat(num);
};

export const concatToStrFromArray = (arr: FilterDefinition, sep: string) => {
    let retVal = arr.value.reduce(
        (acc: string, val: string) => (acc += val + sep),
        ""
    );
    return retVal.slice(0, -1);
};

export function convertToStringArray(array: Option[]) {
    return array.map((ele) => ele.label);
}

export const validText = (value, regex) => {
    let currentEmail = value;
    if (!regex.test(currentEmail.replace(/\s/g, ""))) {
        return false;
    }
    return true;
};

export function getHomePage(role) {
    if (role === "Admin") {
        return "/dashboards/competitor-analysis";
    } else if (role === "Standard User") {
        return "/dashboards/competitor-analysis";
    } else if (role === "Operator") {
        return "/file-management/catalogs";
    }
}

const useIdleTimer = (timeout = 300000) => {
    const navigate = useNavigate();
    useEffect(() => {
      let timer;
      const resetTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(logoutUser, timeout);
      };
  
      const logoutUser = () => {
        console.log('User is idle. Logging out...');
        navigate('/');
      };
  
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keydown', resetTimer);
      window.addEventListener('mousedown', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer();
      return () => {
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keydown', resetTimer);
        window.removeEventListener('mousedown', resetTimer);
        window.removeEventListener('scroll', resetTimer);
        clearTimeout(timer);
      };
    }, [navigate, timeout]);
  
    return null;
};

export const createUserGroupMap = (groups) => {
    return groups.reduce((acc, { group_name, user_list }) => {
        user_list.forEach((user) => {
        if (!acc[user]) {
          acc[user] = [];
        }
        acc[user].push(group_name);
      });
      return acc;
    }, {});
  };

export default useIdleTimer;