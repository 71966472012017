import { darken } from '@mui/system';

import { useDispatch } from 'react-redux';

const ColorPalette = () => {
    
    const buttonStyle = {
        border: 'none',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease',
    };

  const dispatch = useDispatch();
  const colors = [darken('#5569ff', 0.7), darken('#57CA22', 0.7)];
  const names = ['PureLightTheme', 'GreenTheme'];

  const handleColorClick = (color) => {
    console.log(`Color ${color} selected`);
    const index = colors.findIndex(it => it === color);
    dispatch({
      type: 'SET_USER_DATA',
      payload: {
        theme: names[index]
      }
    });
  };

  return (
    <div style={{ display: 'flex', gap: '10px', margin: '20px' }}>
      {colors.map((color) => (
        <button
          key={color}
          onClick={() => handleColorClick(color)}
          style={{
            ...buttonStyle,
            backgroundColor: color,
            border: 'none',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
        ></button>
      ))}
    </div>
  );
};

export default ColorPalette;
