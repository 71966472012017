import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserManagementProvider } from 'src/content/applications/Users/UserManagementProvider';
import useIdleTimer from 'src/Utils';

const queryClient = new QueryClient();
Amplify.configure(awsconfig);

function App() {
  const content = useRoutes(router);
  useIdleTimer(600000);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <UserManagementProvider>
            {content}
          </UserManagementProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
