import { Amplify, Auth, Storage } from 'aws-amplify';
import { API } from 'aws-amplify/lib-esm';

Amplify.configure({
    Auth: {
        identityPoolId: window.env.REACT_APP_IDENTITY_POOL_ID,
        region: window.env.REACT_APP_REGION,
        identityPoolRegion: window.env.REACT_APP_IDENTITY_POOL_REGION,
        userPoolId: window.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: window.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: window.env.REACT_APP_MANDATORY_SIGN_IN,
        signUpVerificationMethod: window.env.REACT_APP_SIGN_UP_VERIFICATION_METHOD,
        authenticationFlowType: window.env.REACT_APP_AUTHENTICATION_FLOW_TYPE,
    },
    Storage: {
        AWSS3: {
            bucket: window.env.REACT_APP_S3_BUCKET,
            region: window.env.REACT_APP_S3_REGION,
        }
    },
    API: {
        endpoints: [
            {
                name: window.env.REACT_APP_TEABASE_NAME,
                endpoint: window.env.REACT_APP_TEABASE_ENDPOINT
            },
            {
                name: window.env.REACT_APP_ADMIN_QUERIES_ENDPOINT,
                endpoint: window.env.REACT_APP_ADMIN_QUERIES_ENDPOINT
            }
        ]
    }
});

const currentConfig = {
    Auth: Auth.configure(),
    Storage: Storage.configure(),
    API: API.configure(),
};

export default currentConfig;
