/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $username: String!
    $lastname: String!
    $password: String!
    $email: String!
    $role: String!
  ) {
    createUser(
      username: $username
      lastname: $lastname
      password: $password
      email: $email
      role: $role
    ) {
      message
    }
  }
`;
export const updatePrivileges = /* GraphQL */ `
  mutation UpdatePrivileges(
    $username: String!
    $attributes: [CognitoAttribute]
  ) {
    updatePrivileges(username: $username, attributes: $attributes) {
      message
    }
  }
`;
export const processFile = /* GraphQL */ `
  mutation ProcessFile(
    $BucketName: String
    $TableName: String
    $Key: String
    $RequestID: String
    $RequestDate: String
    $Mapper: [FieldMapper]
  ) {
    processFile(
      BucketName: $BucketName
      TableName: $TableName
      Key: $Key
      RequestID: $RequestID
      RequestDate: $RequestDate
      Mapper: $Mapper
    )
  }
`;
export const uploadFile = /* GraphQL */ `
  mutation UploadFile(
    $key: String!
    $user_name: String!
    $file_category: String!
    $file_type: String!
  ) {
    uploadFile(
      key: $key
      user_name: $user_name
      file_category: $file_category
      file_type: $file_type
    )
  }
`;
export const deleteTeaTalkJob = /* GraphQL */ `
  mutation DeleteTeaTalkJob($jobId: String!) {
    deleteTeaTalkJob(jobId: $jobId) {
      status
      message
      arguments
    }
  }
`;
export const uploadTeaTalkFile = /* GraphQL */ `
  mutation UploadTeaTalkFile($input: [String]) {
    uploadTeaTalkFile(input: $input) {
      code
      msg
    }
  }
`;
export const publishTeaTalkJobAudio2TextResult = /* GraphQL */ `
  mutation PublishTeaTalkJobAudio2TextResult(
    $result: TeaTalkJobAudio2TextResultInput
  ) {
    publishTeaTalkJobAudio2TextResult(result: $result) {
      jobId
      status
      arguments
    }
  }
`;
export const deleteStandard = /* GraphQL */ `
  mutation DeleteStandard($std_name: String!) {
    deleteStandard(std_name: $std_name) {
      code
      msg
    }
  }
`;
export const editStandard = /* GraphQL */ `
  mutation EditStandard(
    $std_name: String!
    $std_category: String!
    $std_status: String!
    $image_path: String
    $user_name: String
    $user_group: String
    $std_customer: String
  ) {
    editStandard(
      std_name: $std_name
      std_category: $std_category
      std_status: $std_status
      image_path: $image_path
      user_name: $user_name
      user_group: $user_group
      std_customer: $std_customer
    ) {
      code
      msg
    }
  }
`;
export const deleteStandardHistory = /* GraphQL */ `
  mutation DeleteStandardHistory($std_name: String, $std_category: String) {
    deleteStandardHistory(std_name: $std_name, std_category: $std_category) {
      code
      msg
    }
  }
`;
export const deleteAllStandardHistory = /* GraphQL */ `
  mutation DeleteAllStandardHistory {
    deleteAllStandardHistory {
      code
      msg
    }
  }
`;
export const quotationGeneration = /* GraphQL */ `
  mutation QuotationGeneration {
    quotationGeneration
  }
`;
export const insertStandardHistory = /* GraphQL */ `
  mutation InsertStandardHistory(
    $year: String!
    $sale_code: String!
    $color_vs_appearance: Float!
    $standard: String!
    $category: String!
    $image_location: String!
    $broker: String
    $offset: Int
    $limit: Int
  ) {
    insertStandardHistory(
      year: $year
      sale_code: $sale_code
      color_vs_appearance: $color_vs_appearance
      standard: $standard
      category: $category
      image_location: $image_location
      broker: $broker
      offset: $offset
      limit: $limit
    ) {
      code
      msg
    }
  }
`;
export const insertStandard = /* GraphQL */ `
  mutation InsertStandard(
    $std_name: String!
    $std_category: String!
    $std_status: String!
    $image_path: String!
    $user_name: String
    $user_group: String
    $std_customer: String
  ) {
    insertStandard(
      std_name: $std_name
      std_category: $std_category
      std_status: $std_status
      image_path: $image_path
      user_name: $user_name
      user_group: $user_group
      std_customer: $std_customer
    )
  }
`;
export const insertCustomer = /* GraphQL */ `
  mutation InsertCustomer($customer_name: String!, $added_by: String!) {
    insertCustomer(customer_name: $customer_name, added_by: $added_by)
  }
`;
export const executeTeaTalkJobText2PDF = /* GraphQL */ `
  mutation ExecuteTeaTalkJobText2PDF($input: [String]) {
    executeTeaTalkJobText2PDF(input: $input) {
      code
      data {
        msg
        pdfpath
      }
    }
  }
`;
export const publishSimilarityMatches = /* GraphQL */ `
  mutation PublishSimilarityMatches($result: SimilarityMatchingInput) {
    publishSimilarityMatches(result: $result) {
      jobId
      status
      arguments
    }
  }
`;
export const updateAttributes = /* GraphQL */ `
  mutation UpdateAttributes($input: UpdateAttribute) {
    updateAttributes(input: $input)
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($username: String) {
    enableUser(username: $username)
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($username: String) {
    disableUser(username: $username)
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($username: String) {
    deleteUser(username: $username)
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($role_name: String) {
    deleteRole(role_name: $role_name)
  }
`;
export const updateCompanyEmailAndLogo = /* GraphQL */ `
  mutation UpdateCompanyEmailAndLogo(
    $email: String!
    $logo: String!
    $address: String!
    $phone: String!
    $fax: String!
  ) {
    updateCompanyEmailAndLogo(
      email: $email
      logo: $logo
      address: $address
      phone: $phone
      fax: $fax
    )
  }
`;
export const updateQuotationData = /* GraphQL */ `
  mutation UpdateQuotationData(
    $b_price: String!
    $b_moq: String!
    $b_max: String!
    $b_description: String!
    $quotation_status: String!
  ) {
    updateQuotationData(
      b_price: $b_price
      b_moq: $b_moq
      b_max: $b_max
      b_description: $b_description
      quotation_status: $quotation_status
    )
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $customer_name: String!
    $emails: [String!]
    $standards: [String!]
  ) {
    createCustomer(
      customer_name: $customer_name
      emails: $emails
      standards: $standards
    )
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $customer_name: String!
    $emails: [String!]
    $standards: [String!]
    $address: String!
  ) {
    updateCustomer(
      customer_name: $customer_name
      emails: $emails
      standards: $standards
      address: $address
    )
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($customer_name: String!) {
    deleteCustomer(customer_name: $customer_name)
  }
`;
export const addAudit = /* GraphQL */ `
  mutation AddAudit($audit: AuditData) {
    addAudit(audit: $audit) {
      code
      msg
    }
  }
`;
export const insertGroup = /* GraphQL */ `
  mutation InsertGroup($group_name: String!, $user_list: [String!]!) {
    insertGroup(group_name: $group_name, user_list: $user_list) {
      code
      msg
      result
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($group_name: String!) {
    deleteGroup(group_name: $group_name) {
      code
      msg
      result
    }
  }
`;
