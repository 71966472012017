import { useContext, useState, useEffect } from "react";

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";

import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MmsTwoToneIcon from "@mui/icons-material/MmsTwoTone";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";
import HowToVoteTwoToneIcon from "@mui/icons-material/HowToVoteTwoTone";
import LocalPharmacyTwoToneIcon from "@mui/icons-material/LocalPharmacyTwoTone";
import TrafficTwoToneIcon from "@mui/icons-material/TrafficTwoTone";
import WorkspacePremiumTwoToneIcon from "@mui/icons-material/WorkspacePremiumTwoTone";
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import {
  CurrencyExchange,
  HeadsetMic,
  SupervisedUserCircle,
} from "@mui/icons-material";
import { Auth } from "aws-amplify";
import HideRoute from "src/HideRoute";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  const [user, setUserData] = useState({ name: "", role: "" });
  useEffect(() => {
    if (user.name === "") {
      Auth.currentAuthenticatedUser()
        .then((data) => {
          const userData = {
            name: data.attributes["name"],
            role: data.attributes["custom:role"],
          };
          setUserData(userData);
        })
        .catch((err) => console.log(err));
    }
  });

  return (
    <>
      <MenuWrapper>
        <List
          component="div"
          subheader={
            <Typography>
              TeaMate
            </Typography>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <HideRoute
                component={
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboards/competitor-analysis"
                      startIcon={<BrightnessLowTwoToneIcon />}
                    >
                      Competitor Analysis
                    </Button>
                  </ListItem>
                }
                categoryname="CompetitorAnalysis"
                role={user.role}
              />
              <HideRoute
                component={
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboards/weather-analytics"
                      startIcon={<WbSunnyOutlinedIcon />}
                    >
                      Weather Analytics
                    </Button>
                  </ListItem>
                }
                categoryname="WeatherAnalytics"
                role={user.role}
              />
              <HideRoute
                component={
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboards/price-history"
                      startIcon={<DesignServicesTwoToneIcon />}
                    >
                      Price History
                    </Button>
                  </ListItem>
                }
                categoryname="PriceHistory"
                role={user.role}
              />
             <HideRoute
                component={
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboards/heat-map"
                      startIcon={<LocalOfferOutlinedIcon />}
                    >
                      Price Heat Map
                    </Button>
                  </ListItem>
                }
                categoryname="HeatMap"
                role={user.role}
              />
              <HideRoute
                component={
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboards/exchange-rates"
                      startIcon={<CurrencyExchange />}
                    >
                      Exchange Rates
                    </Button>
                  </ListItem>
                }
                categoryname="ExchangeRate"
                role={user.role}
              />
              <HideRoute
                component={
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/file-management/market-report/export"
                        startIcon={<HowToVoteTwoToneIcon />}
                      >
                        Export Market Reports
                      </Button>
                    </ListItem>
                }
                categoryname="FileExport"
                role={user.role}
              />
            </List>
          </SubMenuWrapper>
        </List>
        <HideRoute
          component={
            <List
              component="div"
              subheader={
                <Typography>
                  TeaTalk
                </Typography>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/artificial-intelligence/teatalk"
                      startIcon={<HeadsetMic />}
                    >
                      Automatic Taster Notes
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          }
          categoryname="TeaTalk"
          role={user.role}
        />
        <HideRoute
          component={
            <List
              component="div"
              subheader={
                <Typography>
                  TeaRetina
                </Typography>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tearetina/capture-standards"
                      startIcon={<LocalPharmacyTwoToneIcon />}
                    >
                      Capture Standards
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tearetina/manage-standards"
                      startIcon={<SupervisedUserCircle />}
                    >
                      Manage Standards
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tearetina/match-standards"
                      startIcon={<SupervisedUserCircle />}
                    >
                      Match Standards
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tearetina/match-history"
                      startIcon={<SupervisedUserCircle />}
                    >
                      History
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          }
          categoryname="TeaRetinaManage"
          role={user.role}
        />
        <HideRoute
          component={
            <List
              component="div"
              subheader={
                <Typography>
                  TeaQuotation
                </Typography>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tea-quotation/request"
                      startIcon={<LocalPharmacyTwoToneIcon />}
                    >
                      Quotation Requests
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          }
          categoryname="TeaQuotationRequest"
          role={user.role}
        />
        <HideRoute
          component={
            <List
              component="div"
              subheader={
                <Typography>
                  Customer Management
                </Typography>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/customers/register"
                      startIcon={<LocalPharmacyTwoToneIcon />}
                    >
                      Register
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/customers/view"
                      startIcon={<LocalPharmacyTwoToneIcon />}
                    >
                      List Customers
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          }
          categoryname="TeaQuotationRequest"
          role={user.role}
        />
        <HideRoute
          component={
            <List
              component="div"
              subheader={
                <Typography>
                  User Management
                </Typography>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/user-management/view"
                      startIcon={<LocalPharmacyTwoToneIcon />}
                    >
                      Users
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/user-management/role-management"
                      startIcon={<SafetyCheckIcon />}
                    >
                      Roles
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/user-management/group-management"
                      startIcon={<SafetyCheckIcon />}
                    >
                      Groups
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/user-management/register-user"
                      startIcon={<SupervisedUserCircle />}
                    >
                      Registration
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          }
          categoryname="UserManagement"
          role={user.role}
        />
        <HideRoute
          component={
            <List
              component="div"
              subheader={
                <Typography>
                  File Management
                </Typography>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/file-management/catalogs"
                      startIcon={<BallotTwoToneIcon />}
                    >
                      Upload Catalogs
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/file-management/post-auction-reports"
                      startIcon={<WorkspacePremiumTwoToneIcon />}
                    >
                      Upload Auction Reports
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/file-management/market-report/import"
                      startIcon={<TrafficTwoToneIcon />}
                    >
                      Upload Market Reports
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          }
          categoryname="FileUpload"
          role={user.role}
        />
        <HideRoute
          component={
            <List
              component="div"
              subheader={
                <Typography>
                  Audit Operations
                </Typography>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/audit/upload-tasks"
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      View File Upload Tasks
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/audit/user-actions"
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      View User Actions
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          }
          categoryname="FileUploadTask"
          role={user.role}
        />
        <HideRoute
          component={
          <List
            component="div"
            subheader={
              <Typography>
                Management
              </Typography>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/settings"
                    startIcon={<TableChartTwoToneIcon />}
                  >
                    Settings
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          }
          categoryname="SettingsPage"
          role={user.role}
        />
              {/*</MenuWrapper><ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/profile/settings"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Account Settings
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
